<template>
    <div class="container mt-5">
        <h3 class="text-center mb-4">Gestión de Préstamos de Equipos</h3>

        <!-- Formulario para agregar un nuevo préstamo -->
        <form @submit.prevent="agregarPrestamo" class="card p-4 shadow-sm mb-5">
            <h4 class="card-title text-primary mb-3">Agregar nuevo préstamo</h4>

            <!-- Dividido en dos columnas -->
            <div class="row">
                <div class="col-6">
                    <div class="col-md-12">
                        <label for="docente" class="form-label">Docente</label>
                        <select class="form-control" id="docente" v-model="nuevoPrestamo.docente" required>
                            <option v-for="docente in docentes" :key="docente.id" :value="docente.nombre">{{
                                docente.nombre }}</option>
                        </select>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="equipo" class="form-label">Equipo</label>
                        <select class="form-control" id="equipo" v-model="nuevoPrestamo.equipo" required>
                        <option v-for="equipo in equiposFiltrados" :key="equipo.id" :value="equipo.nombre">
                            {{ equipo.nombre }}
                        </option>
                    </select>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="utilidad" class="form-label">Utilidad del equipo</label>
                        <select class="form-control" id="utilidad" v-model="nuevoPrestamo.utilidad" required>
                            <option value="Presentación">Presentación</option>
                            <option value="Clase">Clase</option>
                            <option value="Reunión">Reunión</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="fechaEntrega" class="form-label">Fecha de entrega</label>
                        <input type="date" class="form-control" id="fechaEntrega" v-model="nuevoPrestamo.fechaEntrega"
                            min="2024-01-01" max="2024-12-31" required />
                    </div>

                    <button type="submit" class="btn btn-primary w-100 mt-3">
                <i class="bi bi-plus-circle"></i> Agregar préstamo
            </button>
                </div>

                <div class="col-6">
                    <div class="card p-4 shadow-sm">
                        <h4 class="card-title text-primary mb-3">Estadísticas de Equipos</h4>
                        <p><strong>Total de equipos registrados:</strong> {{ totalEquipos }}</p>
                        <p><strong>Equipos en uso:</strong> {{ equiposEnUso }}</p>
                        <p><strong>Equipos dañados:</strong> {{ equiposDanados }}</p>
                    </div>

                    <div class="input-group mb-3">
                        <!-- Input para agregar equipos -->
                        <input type="text" class="form-control" placeholder="Nombre del equipo"
                            v-model="nuevoEquipo.nombre" />
                        <button class="btn btn-success" @click="agregarEquipo">
                            <i class="bi bi-plus-circle"></i> Agregar Equipo
                        </button>
                    </div>

                    <div class="input-group mb-3">
                        <!-- Dropdown para seleccionar equipo a eliminar -->
                        <select v-model="equipoSeleccionado" class="form-select">
                            <option v-for="equipo in equiposDisponibles" :key="equipo.id" :value="equipo.id">
                                {{ equipo.nombre }}
                            </option>
                        </select>
                        <button class="btn btn-danger" @click="eliminarEquipo">
                            <i class="bi bi-trash"></i> Eliminar Equipo
                        </button>
                    </div>
                </div>

            </div>

         
        </form>

        <!-- Tabla de equipos en préstamo -->
        <div class="table-responsive">
            <table class="table table-striped table-hover mt-4 shadow-lg">
                <thead class="table-dark">
                    <tr>
                        <th>Docente</th>
                        <th>Equipo</th>
                        <th>Utilidad</th>
                        <th>Fecha (Préstamo / Entrega)</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(prestamo, index) in prestamos" :key="index" :style="obtenerEstiloFila(prestamo)">
                        <td>{{ prestamo.docente }}</td>
                        <td>{{ prestamo.equipo }}</td>
                        <td>{{ prestamo.utilidad }}</td>
                        <td>
                            {{ prestamo.fechaPrestamo }} / {{ prestamo.fechaEntrega }}
                        </td>
                        <td>
                            <span :class="obtenerClaseEstado(prestamo)" class="badge rounded-pill text-white">{{
                                prestamo.estado }}</span>
                        </td>
                        <td>
                            <!-- Botón para confirmar entrega con icono -->
                            <button class="btn btn-success btn-sm me-2" @click="confirmarEntrega(prestamo)">
                                <i class="bi bi-check-circle"></i>
                            </button>

                            <!-- Botón para eliminar préstamo solo para el administrador -->
                            <button class="btn btn-danger btn-sm" v-if="esAdministrador"
                                @click="eliminarPrestamo(index)">
                                <i class="bi bi-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            docentes: [
                { id: 1, nombre: 'Profesor A' },
                { id: 2, nombre: 'Profesor B' },
                { id: 3, nombre: 'Profesor C' },
                { id: 4, nombre: 'Profesor D' },
                { id: 5, nombre: 'Profesor E' }
            ],
            equiposDisponibles: [
                { id: 1, nombre: 'Videobeam 1' },
                { id: 2, nombre: 'Videobeam 2' },
                { id: 3, nombre: 'Parlante 1' },
                { id: 4, nombre: 'Micrófono' }
            ],
            equipoSeleccionado: null,
            prestamos: [],
            nuevoPrestamo: {
                docente: '',
                equipo: '',
                utilidad: '',
                fechaEntrega: '',
                estado: 'En uso',
                fechaPrestamo: ''
            },
            nuevoEquipo: {
                nombre: ''
            },
            esAdministrador: true, // Esto es para simular si es un administrador
        };
    },
    computed: {
        // Contar los equipos en total
        totalEquipos() {
            return this.equiposDisponibles.length;
        },
        // Contar los equipos en uso
        equiposEnUso() {
            return this.prestamos.filter(prestamo => prestamo.estado === 'En uso').length;
        },
        // Contar los equipos dañados
        equiposDanados() {
            return this.prestamos.filter(prestamo => prestamo.estado === 'Dañado').length;
        },
        equiposFiltrados() {
        return this.equiposDisponibles.filter(equipo => 
            !this.prestamos.some(prestamo => prestamo.equipo === equipo.nombre && prestamo.estado === 'En uso')
        );
    }
    },
    methods: {
        agregarPrestamo() {
            const nuevoPrestamo = { ...this.nuevoPrestamo };
            nuevoPrestamo.fechaPrestamo = new Date().toLocaleDateString(); // Asignar la fecha actual como fecha de préstamo
            this.prestamos.push(nuevoPrestamo);
            this.limpiarFormulario();
        },
        limpiarFormulario() {
            this.nuevoPrestamo.docente = '';
            this.nuevoPrestamo.equipo = '';
            this.nuevoPrestamo.utilidad = '';
            this.nuevoPrestamo.fechaEntrega = '';
        },
        equipoEnUso(equipoSeleccionado) {
            return this.prestamos.some(prestamo => prestamo.equipo === equipoSeleccionado && prestamo.estado === 'En uso');
        },
        agregarEquipo() {
            const nuevoNombre = this.nuevoEquipo.nombre.trim();

            // Verificar que el nombre no esté vacío
            if (!nuevoNombre) {
                alert('El nombre del equipo no puede estar vacío.');
                return;
            }

            // Verificar si ya existe un equipo con el mismo nombre
            const equipoExiste = this.equiposDisponibles.some(equipo => equipo.nombre.toLowerCase() === nuevoNombre.toLowerCase());
            if (equipoExiste) {
                alert('El equipo ya existe.');
                return;
            }

            // Si pasa las validaciones, agregar el equipo
            const nuevoId = this.equiposDisponibles.length + 1;
            this.equiposDisponibles.push({ id: nuevoId, nombre: nuevoNombre });
            this.nuevoEquipo.nombre = ''; // Limpiar el campo del nuevo equipo
            alert('Equipo agregado exitosamente.');
        },
        eliminarEquipo() {
            if (this.equipoSeleccionado === null) {
                alert('Por favor, selecciona un equipo para eliminar.');
                return;
            }

            // Filtrar la lista para eliminar el equipo seleccionado
            this.equiposDisponibles = this.equiposDisponibles.filter(equipo => equipo.id !== this.equipoSeleccionado);

            // Limpiar la selección después de eliminar
            this.equipoSeleccionado = null;
            alert('Equipo eliminado exitosamente.');
        },
        obtenerEstiloFila(prestamo) {
            if (prestamo.estado === 'En uso') return { backgroundColor: '#28a745', color: 'white' }; // verde
            if (prestamo.estado === 'No entregado') return { backgroundColor: '#dc3545', color: 'white' }; // rojo
            return { backgroundColor: '#ffc107', color: 'black' }; // amarillo
        },
        obtenerClaseEstado(prestamo) {
            if (prestamo.estado === 'En uso') return 'bg-success';
            if (prestamo.estado === 'No entregado') return 'bg-danger';
            return 'bg-warning'; // para amarillo
        },
        confirmarEntrega(prestamo) {
            prestamo.estado = 'Entregado';
        },
        eliminarPrestamo(index) {
            this.prestamos.splice(index, 1);
        }
    }
};
</script>

<style scoped>
/* Estilo personalizado */
body {
    background-color: #f4f7fa;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.card {
    background-color: #ffffff;
    border-radius: 8px;
}

.table th,
table td {
    text-align: center;
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-responsive {
    overflow-x: auto;
}

.table-dark {
    background-color: #343a40;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-success,
.btn-danger {
    transition: transform 0.2s ease-in-out;
}

.btn-success:hover,
.btn-danger:hover {
    transform: scale(1.1);
}

.badge {
    font-size: 14px;
}

.bi {
    font-size: 20px;
}
</style>