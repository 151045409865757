<template>
  <div @click="hideSubButtons">
    <!-- Botón flotante principal -->
    <button
      class="floating-button"
      @click.stop="toggleSubButtons"
      @mouseover="showText = true"
      @mouseout="showText = false"
    >
      <span class="icon">📅</span>
      <span class="text">Horario Academico</span>
    </button>

    <!-- Subbotones -->
    <div v-if="showSubButtons" class="sub-buttons">
      <button class="sub-button" @click.stop="openModal">
        <span class="icon">🕧</span>
        <span class="stext">Horario Escolar</span>
      </button>
      <button class="sub-button" @click.stop="openParentSchedule">
        <span class="icon">👨‍👩‍👧‍👦</span>
        <span class="stext">Horario de Atención</span>
      </button>
    </div>

    <!-- Modal: Horario Escolar -->
    <div
      class="modal fade"
      id="scheduleModal"
      tabindex="-1"
      aria-labelledby="scheduleModalLabel"
      ref="modalElement"
      :inert="!isModalOpen"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title mb-0" id="scheduleModalLabel">🕒 HORARIO ESCOLAR</h5>
            <button type="button" class="btn-close btn-close-white" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered text-center">
              <thead class="table-primary">
                <tr>
                  <th>Nivel</th>
                  <th>Entrada</th>
                  <th>Salida</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(horario, index) in horarios" :key="index">
                  <td>{{ horario.nivel }}</td>
                  <td>{{ horario.entrada }}</td>
                  <td>{{ horario.salida }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Horario de Atención a Padres de Familia -->
    <div
      class="modal fade"
      id="parentScheduleModal"
      tabindex="-1"
      aria-labelledby="parentScheduleModalLabel"
      ref="parentModalElement"
      :inert="!isParentModalOpen"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h4 class="modal-title mb-0" id="parentScheduleModalLabel">
              👨‍👩‍👧‍👦 HORARIO DE ATENCIÓN A PADRES DE FAMILIA
            </h4>
            <button type="button" class="btn-close btn-close-white" @click="closeParentModal"></button>
          </div>
          <div class="modal-body">
            <!-- Aquí se muestra la imagen -->
            <img
              :src="imageUrl"
              alt="Horario de Atención a Padres de Familia"
              style="width: 100%; height: auto;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { Modal } from "bootstrap";

import HAPF from "../assets/HorariosClases/HorarioAtencionPadresFamilia.jpg";

export default {
  setup() {
    /* --------------------------------------------------
     * Referencias al DOM y variables reactivas
     * -------------------------------------------------- */
    const modalElement = ref(null);
    const parentModalElement = ref(null);

    let modalInstance = null;
    let parentModalInstance = null;

    // Control de apertura/cierre de modales (para el atributo inert)
    const isModalOpen = ref(false);
    const isParentModalOpen = ref(false);

    // URL de la imagen
    const imageUrl = ref(
      HAPF,
    );

    // Datos del horario escolar
    const horarios = ref([
      { nivel: "Preescolar", entrada: "7:00 A.M.", salida: "12:00 M." },
      { nivel: "Primaria", entrada: "6:00 A.M.", salida: "12:30 P.M." },
      { nivel: "Secundaria", entrada: "6:00 A.M.", salida: "1:30 P.M." },
      { nivel: "Media", entrada: "6:00 A.M.", salida: "1:30 P.M." },
    ]);

    // Mostrar/ocultar texto del botón principal
    const showText = ref(false);

    // Mostrar/ocultar subbotones
    const showSubButtons = ref(false);

    /* --------------------------------------------------
     * Funciones para el modal de Horario Escolar
     * -------------------------------------------------- */
    const openModal = () => {
      if (modalInstance) {
        modalInstance.show();
        isModalOpen.value = true;
      }
    };

    const closeModal = () => {
      if (modalInstance) {
        modalInstance.hide();
        isModalOpen.value = false;
        // Devolver foco al botón principal
        document.querySelector(".floating-button")?.focus();
      }
    };

    /* --------------------------------------------------
     * Funciones para el modal de Horario de Atención
     * -------------------------------------------------- */
    const openParentSchedule = () => {
      if (parentModalInstance) {
        parentModalInstance.show();
        isParentModalOpen.value = true;
      }
    };

    const closeParentModal = () => {
      if (parentModalInstance) {
        parentModalInstance.hide();
        isParentModalOpen.value = false;
        // Devolver foco al botón principal
        document.querySelector(".floating-button")?.focus();
      }
    };

    /* --------------------------------------------------
     * Funciones para subbotones
     * -------------------------------------------------- */
    const toggleSubButtons = () => {
      showSubButtons.value = !showSubButtons.value;
    };

    const hideSubButtons = () => {
      showSubButtons.value = false;
    };

    /* --------------------------------------------------
     * Ciclo de vida del componente
     * -------------------------------------------------- */
    onMounted(() => {
      // Instancia del modal de Horario Escolar
      if (modalElement.value) {
        modalInstance = new Modal(modalElement.value);
      }
      // Instancia del modal de Horario de Atención
      if (parentModalElement.value) {
        parentModalInstance = new Modal(parentModalElement.value);
      }
    });

    onUnmounted(() => {
      if (modalInstance) {
        modalInstance.dispose();
      }
      if (parentModalInstance) {
        parentModalInstance.dispose();
      }
    });

    /* --------------------------------------------------
     * Retornar variables y métodos al template
     * -------------------------------------------------- */
    return {
      modalElement,
      parentModalElement,
      isModalOpen,
      isParentModalOpen,

      imageUrl,
      horarios,

      showText,
      showSubButtons,

      openModal,
      closeModal,
      openParentSchedule,
      closeParentModal,

      toggleSubButtons,
      hideSubButtons,
    };
  },
};
</script>

<style>

.table {
  width: 100%;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1500;
  transition: all 0.5s ;
}

.floating-button:hover{
  display: flex;
  transition: all 0.5s ;
  align-items: left;
  justify-content: left;
  width: 220px;
  height: 50px;
  border-radius: solid 50%;
  justify-content: left;
  z-index: 1500;
}

.floating-button:hover .text {
  opacity: 1;
  transform: translateX(1);
  width: 220px;
}

.text {
  position: absolute;
  width: 50px;
  height: 50px;
  padding-left: 50px;
  display: flex;
  align-items: center;
  color: black;
  opacity: 0;
  transform: translateX(-5px);
  transition: all 0.5s ease;
  white-space: nowrap;
}

.sub-buttons {
  position: fixed;
  bottom: 80px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1100;
}

.icon {
  font-size: 28px;
  transition: all 1s ;
  padding-top: auto;
  padding-bottom: auto;
}


.sub-button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 10px;
  display: flex;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1500;
  transition: all 0.5s;
}

.sub-button:hover {
  background-color: #d7eaff;
  transform: scale(1.01);
  width: 220px;
  justify-content: flex-start;
}

.sub-button:hover .stext {
  opacity: 1;
  transform: translateX(0);
}

.stext {
  position: absolute;
  width: 220px;
  height: 50px;
  padding-left: 50px;
  display: flex;
  align-items: center;
  color: black;
  opacity: 0;
  transform: translateX(-5px);
  transition: all 0.5s ease;
  white-space: nowrap;
}



</style>