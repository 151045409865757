<template>
  <div class="container - fluid">
    <div class="row my-3">
      <div class="header"></div>   

      <div class="col-md-2">
        <h6 class="text-primary text-white py-2">Horario de Clases</h6>

        <!-- List for large screens -->
        <!-- List for large screens -->
        <ul class="list-group d-none d-md-block">
          <li v-for="grado in grados" :key="grado" class="list-group-item d-flex align-items-center"
            @click="seleccionarGrado(grado)" :class="{ active: grado === gradoSeleccionado }"
            style="cursor: pointer;" :aria-label="'Select grade ' + grado"            >
            <span class="badge rounded-pill me-2"
              style="min-width: 2rem; text-align: center; background-color: var(--color-co); color: var(--color-se);">
              {{ obtenerNumeroGrado(grado) }}°
            </span>
            {{ grado }}
          </li>
        </ul>

        <!-- Select for small screens -->
        <select class="form-select my-4 d-block d-md-none" v-model="gradoSeleccionado" @change="cargarPDFs"
          aria-label="Select grade">
          <option value="" disabled>Seleccione un grado:</option>
          <option v-for="grado in grados" :key="grado" :value="grado">
            {{ grado }}
          </option>
        </select>
      </div>

      <div class="col-md-10 rounded">
        <!-- Display PDF based on selected grade -->
        <div v-if="gradoSeleccionado">
          <object  :data="pdfPath" type="application/pdf" width="100%" height="740px">
            <p>Tu navegador no soporta la visualización de PDFs. <a :href="pdfPath" target="_blank">Descargar PDF</a></p>
          </object>
        </div>

        <!-- Button to download the PDF -->
        <button @click="descargarHorarioDesdeAssets(gradoSeleccionado)" class="btn btn-primary my-3"
          :disabled="!gradoSeleccionado">
          Descargar Horario en PDF
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import Prejardin from '../assets/HorariosClases/Prejardín.pdf'
import Jardin from '../assets/HorariosClases/Jardín.pdf';
import Transicion from '../assets/HorariosClases/Transición.pdf';
import Primero from '../assets/HorariosClases/Primero.pdf';
import Segundo from '../assets/HorariosClases/Segundo.pdf';
import Tercero from '../assets/HorariosClases/Tercero.pdf';
import Cuarto from '../assets/HorariosClases/Cuarto.pdf';
import Quinto from '../assets/HorariosClases/Quinto.pdf';
import Sexto from '../assets/HorariosClases/Sexto A.pdf';
import SeptimoA from '../assets/HorariosClases/Séptimo A.pdf';
import SeptimoB from '../assets/HorariosClases/Séptimo B.pdf';
import Octavo from '../assets/HorariosClases/Octavo A.pdf';
import Noveno from '../assets/HorariosClases/Noveno A.pdf';
import DecimoA from '../assets/HorariosClases/Décimo A.pdf';
import DecimoB from '../assets/HorariosClases/Décimo B.pdf';
import UndecimoA from '../assets/HorariosClases/Undécimo A.pdf';
import UndecimoB from '../assets/HorariosClases/Undécimo B.pdf';

// Agregar otros grados si es necesario 

export default {
  data() {
    return {
      grados: [
        "Prejardín", "Jardín", "Transición", "Primero", "Segundo", "Tercero",
        "Cuarto", "Quinto", "Sexto", "Séptimo A","Séptimo B", "Octavo", "Noveno", "Décimo A", "Décimo B", "Undécimo A", "Undécimo B"
      ],
      gradoSeleccionado: "Prejardín",
      horarios: {
        "Prejardín": Prejardin,
        "Jardín": Jardin,
        "Transición": Transicion,
        "Primero": Primero,
        "Segundo": Segundo,
        "Tercero": Tercero,
        "Cuarto": Cuarto,
        "Quinto": Quinto,
        "Sexto":Sexto,
        "Séptimo A":SeptimoA,
        "Séptimo B":SeptimoB,
        "Octavo":Octavo,
        "Noveno":Noveno,
        "Décimo A":DecimoA,
        "Décimo B":DecimoB,
        "Undécimo A":UndecimoA,
        "Undécimo B":UndecimoB,
        // Agregar el resto de los grados aquí
      },
      pdfPath: ""
    };
  },
  methods: {
    seleccionarGrado(grado) {
      this.gradoSeleccionado = grado;
      this.cargarPDFs();
    },
    cargarPDFs() {
      if (this.gradoSeleccionado && this.horarios[this.gradoSeleccionado]) {
        this.pdfPath = this.horarios[this.gradoSeleccionado];
      }
    },
    descargarHorarioDesdeAssets(grado) {
      if (this.horarios[grado]) {
        const link = document.createElement('a');
        link.href = this.horarios[grado];
        link.download = this.horarios[grado].split('/').pop();
        link.click();
      }
    },

    obtenerNumeroGrado(grado) {
      const gradoMap = {
        "Prejardín": -2,
        "Jardín": -1,
        "Transición": 0,
        "Primero": 1,
        "Segundo": 2,
        "Tercero": 3,
        "Cuarto": 4,
        "Quinto": 5,
        "Sexto": 6,
        "Séptimo A": 7,
        "Séptimo B": 7,
        "Octavo": 8,
        "Noveno": 9,
        "Décimo A": 10,
        "Décimo B": 10,
        "Undécimo A": 11,
        "Undécimo B": 11
      };

      return gradoMap[grado] || 0; // Retorna el número asociado al grado o 0 si no se encuentra
    }
  },
  watch: {
    gradoSeleccionado() {
      this.cargarPDFs();
    }
  }
};
</script>

<style scoped>
.text-primary {
    background-color: var(--color-pr);
    border-radius: 3px;
    color: var(--color-co);
    font-weight: bold;
    text-transform: uppercase;
}
</style>