<template>
  <footer class="bg-dark text-white py-2">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-3">
          <h1 class="fw-bold">Bienvenidos</h1>
          <h6 class="fw-bold">Año de Crecimiento 2025</h6>
          <hr class="divider">
          <p>Colegio Visión Mundial ASODESI </p>
        </div>
        <div class="col-md-5">
          <img src="@/assets/LogoColVM/Colvm_color2.svg" width="400px" class="img-fluid" alt="colvm" />
          <div class="text-center py-0 my-0">
            <p>Formando en Valores por un Nuevo Ciudadano </p>
            <p>&copy; 2024 Colegio Visión Mundial. Todos los derechos reservados.</p>
          </div>

          <!-- <h5>Enlaces útiles</h5>
          <ul class="list-unstyled">
            <li><a href="#" class="text-white">Inicio</a></li>
            <li><a href="#" class="text-white">Servicios</a></li>
            <li><a href="#" class="text-white">Contacto</a></li>
          </ul> -->
        </div>
        <div class="col-md-3">
          <h5 class="fw-bold">Contáctanos</h5>
          <hr class="divider">
          <ul class="list-unstyled">
            <li><i class="bi bi-telephone"></i> +57 310 2167827</li>
            <li><i class="bi bi-envelope"></i> colegiovisionmundial@gmail.com</li>
            <li><i class="bi bi-geo-alt"></i> Calle 13 No. 14C-10 Barrio 6 de Marzo | Monteria/Cordoba</li>
          </ul>
        </div>
      </div>
     
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooterBar',
};
</script>

<style scoped>
footer a {
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
</style>