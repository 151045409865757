<template>
  <div class="home container-fluid">
    
    <FloatingButton />


    <LastCircularNotification />

    <div id="carouselExampleAutoplaying" class="carousel slide " data-bs-ride="carousel">

      <div class="carousel-inner">

        <div class="carousel-item active">
          <img src="../assets/imgColvm/img (1).jpg" class="d-block w-100" alt="" />
        </div>
        <div class="carousel-item">
          <img src="../assets/imgColvm/img (7).jpg" class="d-block w-100" alt="" />
        </div>

        <!-- <div class="carousel-item ">
          <img src="../assets/imgColvm/img (1).png" type="button" class="d-block btn-img w-100 img-fluid rounded shadow"
            @click.prevent="openForm2" alt="" />
        </div> -->


        <div class="carousel-item">
          <img src="../assets/imgColvm/img (2).jpg" class="d-block w-100" alt="" />
        </div>
        <div class="carousel-item">
          <img src="../assets/imgColvm/img (3).jpg" class="d-block w-100" alt="" />
        </div>
        <div class="carousel-item">
          <img src="../assets/imgColvm/img (4).jpg" class="d-block w-100" alt="" />
        </div>
        <!-- <div class="carousel-item">
          <img src="../assets/imgColvm/img (5).png" type="button" class="d-block btn-img w-100 img-fluid rounded shadow"
            @click.prevent="openForm2" alt="" />
        </div> -->


      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Anterior</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Siguiente</span>
      </button>
    </div>

    <div>

      <section data-aos="slide-up" class="my-5">
       <NuestroServicios />
      </section>

      <section data-aos="fade-up" class="my-5">
       
        <div class="container-fluid" >
          <h1 class="TitleP">¿Quienes Somos?</h1>
          <p class="text-center">Colegio Visión Mundial ASODESI</p>
          <div class="row">
            
            <div class="col-md-4 position-relative">

              <!-- Imagen de fondo -->

              <div data-aos="fade-up" class="d-flex justify-content-center align-items-center"
                style="position: relative; z-index: 2; height: 100%; width: 100%;">
                <div style="position: relative; width: 100%; height: 100%; overflow: hidden;">
                  <img src="../assets/imgColvm/quienesomos.jpg" alt="Descripción de la imagen"
                    style="position: absolute; top: -50%; left: -50%; width: 200%; height: 200%; object-fit: cover; border: none;" />
                </div>
              </div>
            </div>

            <div class="col-md-8">
  
              <div data-aos="zoom-in" class="card-body">

                <p class="card-text my-2">
                  <!-- Visible solo en pantallas medianas o mayores -->
                  Somos un proyecto educativo que pertenece a la Organización World Vision y que
                  viene haciendo impacto social, espiritual, cultural, deportivo, científico y en
                  general en todos los ámbitos de la educación en la ciudad de Montería; Córdoba.
                  Estamos clasificados en Categoría A+ (Muy Superior) otorgada por el Instituto
                  Colombiano para la Evaluación de la Educación ICFES. <br /><br />

                  El Colegio Visión Mundial inició su proceso a inicios del año 2002 ofreciendo un
                  servicio educativo para primaria. Ha sido muy gratificante la evolución del
                  colegio que en el año 2021 cuenta con grado preescolar hasta grado once. Son 21
                  años brindando un servicio con calidad promoviendo el desarrollo y transformación
                  en la vida de los niños, niñas, adolescentes y jóvenes (NNAJ), para que
                  contribuyan de manera significativa en la transformación de la sociedad y en la
                  conservación del medio ambiente. Contamos con personal docente y administrativo
                  altamente calificado, con sentido humano y disposición al servicio. <br /><br />La
                  Institución cuenta con una infraestructura amigable a toda la comunidad donde se
                  generan espacios para el debido esparcimiento y orientaciones de clases. Asimismo,
                  hay organizado todo un sistema de aulas virtuales con recursos interactivos,
                  material de trabajo, clases y evaluaciones en línea que garantizan una educación
                  integral y acorde a los nuevos modelos educativos virtuales y de alternancia.
                  <br /><br />

                  Son muchas las proyecciones que se realizarán para el año 2024, entre las que se
                  tiene aumentar la población estudiantil en el colegio Visión Mundial, al igual que
                  establecer más alianzas estratégicas con el sector productivo, seguir trabajando
                  en las estrategias planeadas en el 2023 y de esta manera fortalecer la gestión
                  educativa.
                </p>

                <p class="card-text my-2 d-block d-md-none">

                  <!-- Visible solo en pantallas pequeñas -->
                  Somos un proyecto educativo que pertenece a la Organización World Vision y que
                  viene haciendo impacto social, espiritual, cultural, deportivo, científico y en
                  general en todos los ámbitos de la educación en la ciudad de Montería; Córdoba.
                  <span id="more-text" class="collapse">

                    Estamos clasificados en Categoría A+ (Muy Superior) otorgada por el Instituto
                    Colombiano para la Evaluación de la Educación ICFES. <br /><br />

                    El Colegio Visión Mundial inició su proceso a inicios del año 2002 ofreciendo un
                    servicio educativo para primaria. Ha sido muy gratificante la evolución del
                    colegio que en el año 2021 cuenta con grado preescolar hasta grado once. Son 21
                    años brindando un servicio con calidad promoviendo el desarrollo y
                    transformación en la vida de los niños, niñas, adolescentes y jóvenes (NNAJ),
                    para que contribuyan de manera significativa en la transformación de la sociedad
                    y en la conservación del medio ambiente. Contamos con personal docente y
                    administrativo altamente calificado, con sentido humano y disposición al
                    servicio. <br /><br />La Institución cuenta con una infraestructura amigable a
                    toda la comunidad donde se generan espacios para el debido esparcimiento y
                    orientaciones de clases. Asimismo, hay organizado todo un sistema de aulas
                    virtuales con recursos interactivos, material de trabajo, clases y evaluaciones
                    en línea que garantizan una educación integral y acorde a los nuevos modelos
                    educativos virtuales y de alternancia. <br /><br />

                    Son muchas las proyecciones que se realizarán para el año 2024, entre las que se
                    tiene aumentar la población estudiantil en el colegio Visión Mundial, al igual
                    que establecer más alianzas estratégicas con el sector productivo, seguir
                    trabajando en las estrategias planeadas en el 2023 y de esta manera fortalecer
                    la gestión educativa.
                  </span>

                </p>

                <button class="btn btn-primary d-md-none" type="button" data-bs-toggle="collapse"
                  data-bs-target="#more-text" aria-expanded="false" aria-controls="more-text">
                  Leer más
                </button>
              </div>
            </div>

          </div>
        </div>
        
      </section>

        <section class="my-5 py-1 shadow">
          <PanoramaViewer />
        </section>

      <section id="mision-vision" class="my-5">
       <MisionVision/>
      </section>

      <section class="my-5">
        <AlianzaSection />
      <!-- <GoogleLogin /> -->
     </section>

    </div>
  </div>
</template>

<script>

import AOS from 'aos';
import 'aos/dist/aos.css';
import FloatingButton from '@/components/FloatingButton.vue';
import LastCircularNotification from '@/components/LastCircularNotification.vue';
import AlianzaSection from '@/components/AlianzaSection.vue';
import PanoramaViewer from "@/components/PanoramaViewer.vue";
import NuestroServicios from "@/components/NuestroServicios.vue";
import MisionVision from "@/components/MisionVision.vue";
//import TextWrapper from "@/components/TextWrapper.vue";
//import GoogleLogin from '@/components/GoogleLogin.vue';

export default {
  

  name: "HomeView",
  mounted() {
    AOS.init({
      duration: 1000, // Duración de la animación en ms
      offset: 100,
      once: false, // Ejecutar la animación solo una vez

      mirror: false,
    });
  },

  components: {
    LastCircularNotification,
    AlianzaSection,
    MisionVision,
    // GoogleLogin,
    // TextWrapper,
    PanoramaViewer,
    NuestroServicios,
    FloatingButton,

  },
  methods: {
    openForm2() {
      window.open("https://forms.gle/4XMCPk4hyTBcgRpg6", "_blank", "width=800,height=1000");
    },
  },
};
</script>

<style scoped>
.home {
  padding-right: 0px;
  padding-left: 0px;
}

p {
  text-align: justify;
}

.imgS {
  object-fit: cover;
  /*La imagen se ajustará al contenedor y puede ser recortada para llenar el espacio */
}

.btn-img {
  opacity: 0.95;
  transition: all 0.5s;
}

.btn-img:hover {
  rotate: calc(0.5deg);
  opacity: 1;
}

/* Estilos para los botones del carrusel */
.carousel-control-prev,
.carousel-control-next {
  background-color: #ff7f32; /* Fondo naranja */
  border-radius: 50%; /* Borde redondeado */
  width: 50px;
  height: 50px;
  padding: 10px;
  position: absolute; /* Posición absoluta para centrar los botones */
  top: 50%; /* Centrado vertical */
  transform: translateY(-50%); /* Ajuste perfecto para el centrado */
}

.carousel-control-prev {
  left: 10px; /* Botón anterior alineado a la izquierda */
}

.carousel-control-next {
  right: 10px; /* Botón siguiente alineado a la derecha */
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: #ff5722; /* Fondo naranja más oscuro al hacer hover */
}

</style>
