<template>
  <div class="home container-fluid">
    <!-- Carrusel -->
    <div id="carouselExampleDark" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/matricula2025/banner2025V2.png" class="d-block btn-img w-100 img-fluid rounded shadow"
            alt="Banner Matrícula 2025" />
          <div class="carousel-caption d-flex flex-column justify-content-center align-items-center">
            <h1>
              <i class="bi bi-arrow-down-circle-fill bounce-icon" style="color: var(--color-se);"></i>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Circulares -->
    <div class="container  shadow my-3 py-3 rounded-2 bg-light ">
      <h2 data-aos="slide-down" class="h1-title rounded-2 p-2 text-center">PROCESO DE MATRÍCULA</h2>
      <hr class="divider">

      <div class="row my-4 justify-content-center">
        <div data-aos="slide-up" v-for="circular in circulares2024" :key="circular.id"
          class="col-md-5 d-flex justify-content-center">
          <div class="card mb-4">
            <h4 class="card-header fw-bold">CIRCULAR N°{{ circular.numero }}</h4>
            <div class="card-body text-center">
              <h6 class="card-text my-3 fw-bold">{{ circular.nombre }}</h6>
              <button class="btn mx-2 my-3 btn-outline-primary" @click="openModal(circular.pdfUrl, circular.nombre)">
                <i class="bi bi-eye"></i> Ver PDF
              </button>
              <button class="btn mx-2 my-3 btn-outline-success" @click="redirectToGoogleDrive(circular.pdfUrl)">
                <i class="bi bi-file-earmark-arrow-down-fill"></i> Descargar
              </button>
            </div>
          </div>
        </div>

        <div data-aos="slide-up" class="d-grid my-3 col-md-6">
          <hr class="divider">

          <button class="btnM btn" @click="openGoogleForm">
            <i class="bi bi-caret-right-fill animated-icon"></i>
            Formulario - Matrícula Escolar Año 2025
          </button>
        </div>

      </div>


    </div>
    <div data-aos="slide-up" class="container shadow my-5 rounded-2 bg-light ">
      <h2 class="h1-title rounded-2 p-2 text-center">Uniforme Escolar - COLVM</h2>
      <hr class="divider">
      <UniformeColVMView />

    </div>

    <!-- Modal -->
    <div class="modal shadow rounded fade" id="pdfModal" tabindex="-1" aria-labelledby="pdfModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fs-5 fw-bold" id="pdfModalLabel">{{ modalTitle }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <iframe :src="pdfSrc" type="application/pdf" width="100%" height="600px" frameborder="0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>

    <div data-aos="fade-up" class="container  my-5 rounded-2 bg-light ">
      <h2 class="h1-title rounded-2 p-2 text-center">Listas Escolares 2025</h2>
      <hr class="divider">
      <ListaEscolares />
    </div>

  </div>
</template>

<script>

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Modal } from "bootstrap";
import UniformeColVMView from '@/components/UniformeColVMView.vue';
import ListaEscolares from "@/components/ListaEscolares.vue";


export default {
  name: "MatriculaView",
  mounted() {
    AOS.init({
      duration: 500, // Duración de la animación en ms
      offset: 100,
      once: false, // Ejecutar la animación solo una vez
      mirror: false,
    });
  },
  components: {
    UniformeColVMView,
    ListaEscolares,

  },
  data() {
    return {
      pdfSrc: "",
      modalTitle: "",
      circulares2024: [
        {
          id: 15,
          numero: "15",
          nombre: "Costos educativos, horarios de clases y proceso de matrícula 2025.",
          pdfUrl: "https://drive.google.com/file/d/1z5WYeNi8U1aZESZYeErge2o2_yJZxYqX/preview",
        },
        {
          id: 16,
          numero: "16",
          nombre: "Proceso de matrículas 2025 para estudiantes antiguos y nuevos.",
          pdfUrl: "https://drive.google.com/file/d/1A44ZUduF8B8IMLB7Qqzbxo59VvkDDlXr/preview",
        },
      ],
    };
  },
  methods: {

    openGoogleForm() {
      window.open("https://forms.gle/3A7QeoZMjG6JFMrZA", "_blank");
    },

    openModal(pdfUrl, title) {
      if (pdfUrl && this.validateUrl(pdfUrl)) {
        this.pdfSrc = pdfUrl;
        this.modalTitle = title;
      } else {
        this.pdfSrc = "";
        this.modalTitle = "Recurso no disponible";
      }
      const myModal = new Modal(document.getElementById("pdfModal"));
      myModal.show();
    },
    validateUrl(url) {
      return url.includes("drive.google.com");
    },
    redirectToGoogleDrive(pdfUrl) {
      if (this.validateUrl(pdfUrl)) {
        const viewUrl = pdfUrl.replace('/preview', '/view');
        window.open(viewUrl, '_blank');
      }
    },
  },
};
</script>

<style scoped>
.animated-icon {
  display: inline-block;
  animation: moveRight 1s infinite;
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}


.btnM {
  background: var(--color-co);
  border: 1px solid var(--color-se);
  color: var(--color-se);
  transition: all ease-in-out 0.2s;
  font-size: 1.2rem;
}

.btnM:hover {
  background: var(--color-pr);
  border: 1px solid var(--color-co);
  color: var(--color-co);
  font-weight: bold;
}

.h1-title {
  text-transform: uppercase;
  background: var(--color-se);
  color: var(--color-co);
}

/* Animación del ícono */
@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
}

.bounce-icon {
  animation: bounce 1.5s infinite;
  font-size: 5rem;
  display: inline-block;
}

/* Botón personalizado */
.btnForm {
  background: var(--color-pr);
  color: var(--color-co);
}

.btnForm:hover {
  background: var(--color-pr);
  color: var(--color-co);
}

/* Imagen del carrusel */
.btn-img {
  opacity: 0.9;
  transition: all 0.5s;
}

.btn-img:hover {
  transform: rotate(0.5deg);
  opacity: 1;
}

/* Modal */
.modal-title {
  color: var(--color-pr);
  text-transform: uppercase;
}

/* Tarjetas */
.card {
  background: url('../assets/matricula2025/bcard.png') center center / cover no-repeat;
  opacity: 0.9;
  /* Transparencia del fondo */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: white;
  /* Ajusta el texto para mejor visibilidad */
}

.card-header {
  background-color: rgba(255, 255, 255, 1);
  /* Fondo semi-transparente para el texto */
  color: var(--color-se)
}

.card-body {
  background-color: rgba(255, 255, 255, 0.9);
  /* Fondo semi-transparente para el cuerpo */
  color: black;
  /* Ajusta según el contraste deseado */
}

.card:hover {
  transform: scale(1.02) rotate(0.5deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card:hover .card-header {
  background-color: var(--color-pr);
  color: #ffdacb;
}

.card:hover .card-body {
  background-color: #ffdacb;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: var(--color-se)
}

.home {
  padding: 0;
}
</style>
