<template>
  <div class="">
    <h1 class="text-center TitleP">NUESTROS SERVICIOS</h1>
    <p class="text-center">
      Conoce lo que hacemos para transformar vidas a través de la educación
    </p>

    <div class="row mt-5 mx-5">
      <!-- Servicio 1 -->
      <div class="col-md-4 text-center mb-4">
        <div data-aos="zoom-in" class="service1-card">
          <div class="icon-circle mx-auto mb-3">
            <img src="../assets/seviciosCOLVM/CrianzaTernura.jpg" alt="Crianza con Ternura" />
          </div>
          <div>
            <h5 class="fw-bold">❤️ CRIANZA CON TERNURA</h5>
            <hr class="hr1">
          </div>
          <p class="description">
            Espacio de información, formación y reflexión sobre aspectos relacionados con las funciones parentales
            enfocado en temáticas de la crianza con ternura el cual es un enfoque de desarrollo humano y social de la
            niñez, que busca sustituir la crianza que controla y domina a los niños a través del miedo y del dolor, por
            una crianza fundamentada en el amor.
          </p>
        </div>
      </div>

      <!-- Servicio 2 -->
      <div class="col-md-4 text-center mb-4">
        <div data-aos="zoom-in" class="service2-card">
          <div class="icon-circle mx-auto mb-3">
            <img src="../assets/seviciosCOLVM/FormacionTIC.jpg" alt="Formación TIC" />
          </div>
          <h5 class="fw-bold">💻 FORMACIÓN TIC</h5>
          <hr class="hr2">
          <p class="description">
            Nuestra Institución se ha caracterizado a lo largo del tiempo por brindar a su comunidad educativa espacios
            de formación y capacitación en el área de las tecnologías de la información y comunicación con la firme
            intención de mejorar las competencias básicas tecnológicas de sus principales actores (docentes, directivos
            docentes, estudiantes y padres de familia).
          </p>
        </div>
      </div>

      <!-- Servicio 3 -->
      <div class="col-md-4 text-center mb-4">
        <div data-aos="zoom-in" class="service3-card">
          <div class="icon-circle mx-auto mb-3">
            <img src="../assets/seviciosCOLVM/FormacionReligiosa.jpg" alt="Desarrollo Espiritual" />
          </div>
          <h5 class="fw-bold">⛪ DESARROLLO ESPIRITUAL</h5>
          <hr class="hr3">

          <p class="description">
            El Colegio Visión Mundial, es una institución de formación cristiana, que sigue las enseñanzas de Jesús,
            poniendo en práctica su modelo de vida, en busca de la transformación de la persona. Ser cristiano equivale
            a imitar a Jesús en su doctrina, vida y testimonio.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NuestroServicios',
  mounted() {
    // Aquí puedes agregar lógica de Vue si es necesario
  }
};
</script>

<style scoped>



.hr1 {
  border: 2px solid #E30022;
  margin: 20px auto;
}

.hr2 {
  border: 2px solid #2162FE;
  margin: 20px auto;
}

.hr3 {
  border: 2px solid #eb6f11;
  margin: 20px auto;
}

.service1-card {
  color: #1b1b1b;

}

.service1-card:hover .icon-circle img {
  transform: scale(1.1);
}

.service1-card h5 {
  transition: color 0.3s ease;
  color: #9c0017;
}

.service1-card:hover h5 {
  color: #D3212C;
}


.service1-card .icon-circle {
  width: 120px;
  /* Tamaño del círculo */
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #D3212C;
}

.service1-card:hover .icon-circle {
  transform: scale(1.2);
  background-color: #D3212C;
}

/* Mostrar la descripción al hacer hover */
.service1-card:hover .description {
  opacity: 1;
}




.service2-card {
  color: #1b1b1b;

}

.service2-card:hover .icon-circle img {
  transform: scale(1.1);
}

.service2-card h5 {
  transition: color 0.3s ease;
  color: #2162FE;
}

.service2-card:hover h5 {
  color: #2162FE;
}


.service2-card .icon-circle {
  width: 120px;
  /* Tamaño del círculo */
  height: 120px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #2162FE;
}

.service2-card:hover .icon-circle {
  transform: scale(1.2);
  background-color: #2162FE;
}

/* Mostrar la descripción al hacer hover */
.service2-card:hover .description {
  opacity: 1;
}




.service3-card {
  color: #1b1b1b;

}

.service3-card:hover .icon-circle img {
  transform: scale(1.1);
}

.service3-card h5 {
  transition: color 0.3s ease;
  color: #FF7E14;
}

.service3-card:hover h5 {
  color: #FF7E14;
}


.service3-card .icon-circle {
  width: 120px;
  /* Tamaño del círculo */
  height: 120px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #FF7E14;
}

.service3-card:hover .icon-circle {
  transform: scale(1.2);
  background-color: #FF7E14;
}

/* Mostrar la descripción al hacer hover */
.service3-card:hover .description {
  opacity: 1;
}



.icon-circle img {
  max-width: 90%;
  /* Imagen dentro del círculo */
  transition: transform 0.3s ease;
  border-radius: 100px;

}

/* Estilo para la descripción */
.description {
  font-size: 0.95rem;
  line-height: 1.6;
  opacity: 0.8;
  /* Inicialmente oculta */
  transition: opacity 0.3s ease;
}
</style>