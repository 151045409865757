<template>
  <div class="timeline-container">
    <h1 class="timeline-title  TitleP"><i class="bi bi-clock-history"></i> Reseña Historica</h1>
    
    <p class="">Colegio Visión Mundial ASODESI</p>
    <div class="timeline">
      <div 
        v-for="(section, index) in sections" 
        :key="index" 
        class="timeline-event" 
        data-aos="fade-up" 
        :style="{ background: index % 2 === 0 ? '#fdfdfd' : '#f8f9fa', minHeight: '80vh' }"
      >
        <div class="timeline-marker">
          <span class="event-year">{{ section.year }}</span>
        </div>
        <div class="timeline-line"></div>
        <div class="timeline-content">
          <img v-if="section.image" :src="section.image" alt="" class="event-image" />
          <h2 class="event-title TitleP">
            <i :class="section.icon"></i> {{ section.title }}
          </h2>
          <p v-for="(paragraph, idx) in section.content" :key="idx" class="event-paragraph">
            {{ paragraph }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from "@/assets/Storyline/Previmg.jpg"
import img2 from "@/assets/Storyline/Previmg2.jpg"
import img3 from "@/assets/Storyline/Previmg3.jpg"
import img4 from "@/assets/Storyline/Previmg4.jpg"
import img5 from "@/assets/Storyline/Previmg5.jpg"
import img6 from "@/assets/Storyline/Previmg6.jpg"
import img7 from "@/assets/Storyline/Previmg7.jpg"
import img8 from "@/assets/Storyline/Previmg8.jpg"
import img9 from "@/assets/Storyline/Previmg9.jpg"
import img10 from "@/assets/Storyline/Previmg10.jpg"


export default {
  name: "LineaTiempo",
  data() {
    return {
      sections: [
        {
          year: "2000",
          title: "Inicio de la Construcción",
          icon: "bi bi-building",
          image: img1,
          content: [
            "En abril de 2000, se inicia la construcción del colegio en el barrio 6 de Marzo de Montería.",
            "Este proyecto es una de las iniciativas más ambiciosas de la Organización Visión Mundial, siendo el único colegio de esta ONG en todo el mundo.",
            "La visión del proyecto es ofrecer una educación de calidad a niños y jóvenes de comunidades en condiciones de pobreza y vulnerabilidad."
          ]
        },
        {
          year: "2002",
          title: "Inauguración y Primeros Pasos",
          icon: "bi bi-calendar-event",
          image: img2,
          content: [
            "18 de febrero de 2002: Se programa la inauguración oficial del centro educativo.",
            "19 de febrero de 2002: Se inician las actividades escolares con solo tres aulas y un restaurante escolar, sin servicio de fluido eléctrico.",
            "A pesar de las dificultades iniciales, ASODESI se convierte en una realidad. Inicialmente, solo se ofrece educación preescolar para niños patrocinados por la ONG, con horarios de mañana y tarde."
          ]
        },
        {
          year: "2003",
          title: "Expansión a la Primaria",
          icon: "bi bi-diagram-2",
          image: img3,
          content: [
            "Se abre el ciclo básico de primaria, ampliando la oferta educativa.",
            "La institución continúa funcionando con un enfoque integral que incluye programas musicales como 'Batuta', apoyos alimenticios con el ICBF y actividades espirituales a través de devocionales, todo dirigido al bienestar integral de los estudiantes."
          ]
        },
        {
          year: "2004",
          title: "Ampliación a Secundaria",
          icon: "bi bi-mortarboard",
          image: img4,
          content: [
            "El colegio da un paso más al abrir la básica secundaria, ofreciendo una formación más completa.",
            "Este crecimiento es liderado por el rector Rafael Vega Hernández, licenciado en Humanidades, quien impulsa el desarrollo académico y organizacional de la institución."
          ]
        },
        {
          year: "2006",
          title: "Cambio de Rectoría",
          icon: "bi bi-person-fill",
          image: img5,
          content: [
            "La Esp. Martha Chaves Silva asume la rectoría del colegio, destacándose por su trabajo en la gestión y el bienestar estudiantil.",
            "Establece un convenio con el Plan Mundial de Alimentos para crear un comedor escolar que atiende a los 580 estudiantes en ese momento."
          ]
        },
        {
          year: "2008",
          title: "Centro Interactivo Portales del Siglo XXI",
          icon: "bi bi-display",
          image: img7,
          content: [
            "Se inaugura el Centro Interactivo Portales del Siglo XXI, con dos salas informáticas equipadas con 60 computadoras de última tecnología, servidores y equipos de impresión láser.",
            "Este centro tiene como objetivo ofrecer programas técnicos laborales y cursos ocupacionales a la comunidad, ampliando la oferta educativa y profesional de la región."
          ]
        },
        {
          year: "2010",
          title: "Aprobación para Bachillerato Técnico en Sistemas",
          icon: "bi bi-laptop",
          image: img6,
          content: [
            "El colegio obtiene la aprobación de Bachillerato Técnico en Sistemas mediante la Resolución 0109, lo que permite la formación técnica a un grupo de estudiantes.",
            "Un total de 113 adolescentes y jóvenes reciben este título, lo que les brinda ventajas competitivas en el mercado laboral y facilita su acceso a la educación superior."
          ]
        },
        {
          year: "2013",
          title: "Acreditación Internacional EFQM",
          icon: "bi bi-award",
          image: img8,
          content: [
            "En este año, el colegio alcanza el Primer Nivel de Excelencia en el proceso de Acreditación Internacional en EFQM, reconociendo la calidad educativa y el compromiso con la formación integral de los estudiantes.",
            "A su vez, el colegio es reconocido por su trabajo en los ámbitos académico, deportivo, cultural y ambiental."
          ]
        },
        {
          year: "2021",
          title: "Nueva Proyección y Mejoramiento",
          icon: "bi bi-bar-chart-fill",
          image: img9,
          content: [
            "En 2021, la Mg. Gloria Lucía Madrid Díaz asume la rectoría. Bajo su liderazgo, se implementa un plan de mejoramiento institucional enfocado en cuatro áreas claves: Educando con ternura, Colegio verde, Autosostenibilidad del colegio, y Camino al bilingüismo.",
            "Estas estrategias buscan no solo mejorar la calidad educativa, sino también garantizar la sostenibilidad y el impacto positivo en la comunidad."
          ]
        },
        {
          year: "2024",
          title: "Posicionamiento y Reconocimiento Actual",
          icon: "bi bi-stars",
          image: img10,
          content: [
            "El Colegio Visión Mundial se consolida como una de las instituciones más prestantes de Montería, destacándose no solo en los ámbitos académicos, sino también en los procesos científicos, deportivos, ambientales, convivenciales, espirituales y lúdicos.",
            "Con una infraestructura adecuada para 800 estudiantes, sigue ofreciendo una educación integral basada en principios y valores cristianos.",
            "En la actualidad, sigue siendo un referente educativo y continúa formando a futuros líderes comprometidos con el desarrollo de su comunidad, región y país."
          ]
        }
      ]
    };
  },
  mounted() {
    AOS.init({
      duration: 1000, // Duración de la animación en ms
      offset: 200,
      once: true, // Ejecutar la animación solo una vez
      mirror: false,
    });
  }
};
</script>

<style scoped>
.timeline-container {
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  padding: 30px;
  width: 100%;
  min-height: 100vh;
  overflow-y: none;
  position: relative;
}
.timeline-title {
  text-align: center;
  color: #34495e;

  font-size: 3.5rem;
}
.timeline {
  display: flex;
  flex-direction: column;
  position: relative;
}
.timeline-event {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
  position: relative;
}
.timeline-marker {
  position: relative;
  width: 90px;
  height: 90px;
  background: #3498db;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  margin-bottom: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.event-year {
  font-weight: bold;
}
.timeline-line {
  position: absolute;
  left: 45px;
  top: 90px;
  width: 4px;
  height: calc(100% - 90px);
  background: var(--color-se)
}
.timeline-content {
  background: #ffffff;
  border: 1px solid #dfe6e9;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  width: 85%;
  margin: 0 auto;
  text-align: justify;
}
.event-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}
.event-title {
  font-size: 2rem;
  color: #34495e;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.event-paragraph {
  font-size: 1.3rem;
  color: #555;
  line-height: 1.8;
  margin: 15px 0;
}
</style>
