import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css'; // Importa los estilos CSS de Bootstrap
// import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-icons/font/bootstrap-icons.css'

  
  // Crea la aplicación Vue
  createApp(App)
    .use(router) // Agrega Vue Router si lo usas
    .mount("#app");
