import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PPDView from '../views/PPDView.vue'
import MatriculaView from '../views/MatriculaView.vue'
import ComunicacionesView from '../views/ComunicacionesView.vue'
import EvaluacionDView from '../views/EvaluacionDView.vue'
import ResenaHView from '../views/ResenaHView.vue'
import PrestamoEquipoView from '@/views/PrestamoEquipoView.vue'
import HorarioClasesView from '@/views/HorarioClasesView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/reseña-historica',
    name: 'ResenaHView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ResenaHView
  },
  {
    path: '/politica-privacidad',
    name: 'PPDView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PPDView
  },
  {
    path: '/matricula',
    name: 'MatriculaView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: MatriculaView
  },
  {
    path: '/comunicaciones',
    name: 'ComunicacionesView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ComunicacionesView
  },

  {
     path: '/PrestamoEquipo',
     name: 'PrestamoEquipoView',
     component: PrestamoEquipoView
  },
  
  {
    path: '/HorarioClases',
    name: 'HorarioClasesView',
    component: HorarioClasesView
 },

  {
    path: '/evaluacion-docente',
    name: 'EvaluacionDView',
    component: EvaluacionDView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
