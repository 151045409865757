<template>
    <div class="container py-5">
      <div class="row text-center">
        <!-- Mission -->
        <div data-aos="fade-up" class="col-12 col-md-4 mb-4 mb-md-0">
          <div class="position-relative">
            <div class="bg-danger rounded-circle position-absolute top-50 start-50 translate-middle opacity-25" style="width: 100px; height: 100px;"></div>
            <i class="bi bi-bullseye text-danger fs-1"></i>
          </div>
          <h3 data-aos="zoom-in" class="text-danger my-4">MISIÓN</h3>
          <p data-aos="zoom-in" class="text-muted">
                Formar de manera integral a NNAJ residentes en la ciudad de Montería, con un
              perfil Técnico en Sistemas, orientados con principios cristianos, ambientales,
              culturales, científicos, tecnológicos, deportivos y recreativos. Respondiendo,
              de esta manera, a un ideal de un nuevo ciudadano colombiano que pueda aprender a
              ser, aprender a aprender, aprender a hacer y aprender a convivir con sus deberes
              y derechos; promoviendo así el desarrollo y la transformación humana.
          </p>
        </div>
  
        <!-- Vision -->
        <div data-aos="fade-down" class="col-12 col-md-4 mb-4 mb-md-0">
          <div class="position-relative">
            <div class="bg-primary rounded-circle position-absolute top-50 start-50 translate-middle opacity-25" style="width: 100px; height: 100px;"></div>
            <i class="bi bi-eye text-primary fs-1"></i>
          </div>
          <h3 data-aos="zoom-in" class="text-primary my-4">VISIÓN</h3>
          <p data-aos="zoom-in" class="text-muted">
            Ser una Institución Certificada en Procesos de Calidad para el año 2030, que
              ofrece el Servicio de Educación con el nivel Técnico en Sistemas a la sociedad
              Monteriana, así mismo, ser una institución que evidencia resultados de la
              educación con ternura, el bilingüismo y que trabaja todos sus procesos bajo el
              concepto de colegio verde y la autosostenibilidad.
          </p>
        </div>
  
        <!-- Values -->
        <div data-aos="fade-up" class="col-12 col-md-4">
          <div class="position-relative">
            <div class="bg-success rounded-circle position-absolute top-50 start-50 translate-middle opacity-25" style="width: 100px; height: 100px;"></div>
            <i class="bi bi-gem text-success fs-1"></i>
          </div>
          <h3 data-aos="zoom-in" class="text-success my-4">VALORES</h3>
          <p data-aos="zoom-in" class="text-muted">
            Los valores como la responsabilidad, honestidad, actitud de servicio, credibilidad, sensibilidad y tolerancia guiarán
            los pasos de la institución, cimentados en el amor por nuestra labor y el interés por la formación integral de
            nuestros estudiantes, con la participación de toda la comunidad educativa.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "MissionVisionValues",
  };
  </script>
  
  <style>
  @media (max-width: 768px) {
    .position-relative .rounded-circle {
      width: 80px !important;
      height: 80px !important;
    }
    .fs-1 {
      font-size: 2rem !important;
    }
  }
  </style>
