<template>
    <div class="container my-4">
        <!-- <h2 class="text-center rounded title p-2 mb-4">Uniforme Escolar - COLVM</h2>
        <hr class="divider"> -->

        <!-- Carrusel -->
        <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false">
            <div class="carousel-inner">
                <!-- Slide 1 -->
                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="zoom-container">
                                <img src="../assets/UniformeCOLVM/uniforme_niño.jpg" class="d-block w-100 image"
                                    alt="Uniforme Diario Niño" @mousedown="startDrag" @mousemove="onDrag"
                                    @mouseup="stopDrag" @mouseleave="stopDrag" @touchstart="startDrag"
                                    @touchmove="onDrag" @touchend="stopDrag" @click="handleClick('uniforme_niño.jpg')"
                                    :style="{ transform: `translate(${translateX}px, ${translateY}px) scale(${zoomLevel})` }" />
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="descripcion my-2 p-2">
                                <h4 class="">Uniforme Diario - Niño</h4>
                                <hr class="divider">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        <strong>Camisa guayabera</strong> blanca con dos pliegues en su parte frontal,
                                        con logo del Colegio en la parte frontal superior izquierda.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Pantalón clásico color azul turquí. <strong>Para el caso de Preescolar los
                                            niños usan pantalón corto y medias turquí.</strong>
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Cinturón negro.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Medias largas color azul turquí.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Zapatos negros tipo colegiales.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Tipo o nombre de la tela (camisa): <strong>Oxford</strong>
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Tipo o nombre de la tela (pantalón): <strong>Lino</strong>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- Slide 2 -->
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="zoom-container">
                                <img src="../assets/UniformeCOLVM/uniforme_niña.jpg" class="d-block w-100 image"
                                    alt="Uniforme Diario Niña" @mousedown="startDrag" @mousemove="onDrag"
                                    @mouseup="stopDrag" @mouseleave="stopDrag" @touchstart="startDrag"
                                    @touchmove="onDrag" @touchend="stopDrag" @click="handleClick('uniforme_niña.jpg')"
                                    :style="{ transform: `translate(${translateX}px, ${translateY}px) scale(${zoomLevel})` }" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="descripcion my-2 p-2">
                                <h4 class="">Uniforme Diario - Niña</h4>
                                <hr class="divider">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        <strong>Blusa estilo safari</strong> con cinturón color blanco, con logo del Colegio
                                        en la parte frontal superior izquierda.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Falda color azul turquí con canesú y tres pliegues profundos delante y
                                        detrás, <strong>esta prenda debe ser usada a altura de la rodilla.</strong>
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Medias largas color blanco.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Zapatos negros tipo colegiales.
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Tipo o nombre de la tela (blusa): <strong>Oxford</strong>
                                    </li>
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Tipo o nombre de la tela (Falda): <strong>Lino</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Slide 3 -->
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="zoom-container">
                                <img src="../assets/UniformeCOLVM/uniforme_deportivo.jpg" class="d-block w-100 image"
                                    alt="Uniforme Deportivo" @mousedown="startDrag" @mousemove="onDrag"
                                    @mouseup="stopDrag" @mouseleave="stopDrag" @touchstart="startDrag"
                                    @touchmove="onDrag" @touchend="stopDrag"
                                    @click="handleClick('uniforme_deportivo.jpg')"
                                    :style="{ transform: `translate(${translateX}px, ${translateY}px) scale(${zoomLevel})` }" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="descripcion my-2 p-2">
                                <h4 class="">Uniforme Diario - Deportivo</h4>
                                <hr class="divider">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" style="line-height: 1.5; text-align: justify;">
                                        Camiseta blanca, con franjas azules a los lados y líneas anaranjadas, con
                                        cuello blanco, franjas delgadas azul y anaranjada.

                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <div data-aos="zoom-out" class="text-center p-3">
            <a href="https://drive.google.com/file/d/1VfUYrw1Ie8zByO77d5VaqOG5wLLuqZzY/view?usp=sharing" target="_blank" rel="noopener noreferrer" class="btn btnM">
                <i class="bi bi-caret-right-fill mx-2 animated-icon"></i>Clic aquí para descargar el LOGO
            </a>
        </div>

        <!-- Modal -->
        <div class="modal shadow rounded fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">

                <div class="modal-content">
                    <div class="modal-header  ">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <img :src="modalImageSrc" class="img-fluid" alt="Imagen Ampliada">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            zoomLevel: 1,
            isDragging: false,
            startX: 0,
            startY: 0,
            translateX: 0,
            translateY: 0,
            modalImageSrc: ''
        };
    },
    methods: {
        startDrag(event) {
            const e = event.type === 'touchstart' ? event.touches[0] : event;
            if (this.zoomLevel > 1) {
                this.isDragging = true;
                this.startX = e.clientX - this.translateX;
                this.startY = e.clientY - this.translateY;
            }
        },
        onDrag(event) {
            if (this.isDragging) {
                const e = event.type === 'touchmove' ? event.touches[0] : event;
                this.translateX = e.clientX - this.startX;
                this.translateY = e.clientY - this.startY;
            }
        },
        stopDrag() {
            this.isDragging = false;
        },
        handleClick(image) {
            if (window.innerWidth > 768) { // Detecta si es PC
                this.modalImageSrc = require(`@/assets/UniformeCOLVM/${image}`);
                new Modal(document.getElementById('imageModal')).show();
            } else {
                this.toggleZoom(); // Agrega la funcionalidad de zoom en móviles
            }
        },
        toggleZoom() {
            if (this.zoomLevel === 1) {
                this.zoomLevel = 2;
            } else {
                this.zoomLevel = 1;
                this.translateX = 0;
                this.translateY = 0;
            }
        }
    }
};
</script>

<style scoped>
.animated-icon {
  display: inline-block;
  animation: moveRight 1s infinite;
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.btnM{
  background: var(--color-co);
  border: 1px solid var(--color-se);
  color: var(--color-se);
  transition: all ease-in-out 0.2s;
  font-size: 1.2rem;
}

.btnM:hover{
  background: var(--color-pr);
  border: 1px solid var(--color-co);
  color: var(--color-co);
  font-weight: bold;
}
.title {
   
    background: var(--color-se);
    text-transform: uppercase;
    color: var(--color-co);
}

.list-group-item {
    border: none;
    /* Elimina el borde predeterminado */
    position: relative;
    /* Posiciona el ícono de viñeta correctamente */
    padding-left: 1.5rem;
    /* Añade espacio para el ícono de viñeta */
    list-style-type: disc;
    /* Cambia el tipo de lista a viñetas */
    color: var(--color-se);
    background-color: #f8f9fa;
    font-size: 1.1rem;
    /* Aumenta el tamaño del texto */

}

.list-group-item::before {
    content: '•';
    /* Símbolo de viñeta */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.35rem;
    /* Tamaño del ícono de viñeta */
    color: var(--color-pr);
    /* Color del ícono de viñeta */
}

h4 {
    color: var(--color-pr);
    text-transform: uppercase;
    font-weight: bold;
}


.zoom-container {
    overflow: hidden;
    position: relative;
}

img {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.carousel-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.descripcion {
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.image {
    cursor: pointer;
    touch-action: pan-x pan-y;
}

@media (max-width: 768px) {
    .image {
        transition: transform 0.3s ease;
    }
}

.modal-dialog {
    max-width: 80%;
}

.modal-body img {
    width: 100%;
    height: auto;
}

/* Estilos para los botones del carrusel */
.carousel-control-prev,
.carousel-control-next {
  background-color: #ff7f32; /* Fondo naranja */
  border-radius: 50%; /* Borde redondeado */
  width: 50px;
  height: 50px;
  padding: 10px;
  position: absolute; /* Posición absoluta para centrar los botones */
  top: 50%; /* Centrado vertical */
  transform: translateY(-50%); /* Ajuste perfecto para el centrado */
}

.carousel-control-prev {
  left: 10px; /* Botón anterior alineado a la izquierda */
}

.carousel-control-next {
  right: 10px; /* Botón siguiente alineado a la derecha */
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: #ff5722; /* Fondo naranja más oscuro al hacer hover */
}
</style>
