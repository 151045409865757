<template>
  <div data-aos="slide-up" class="container-fluid">
    <div>
      <h3 data-aos="zoom-in" class="text-center TitleP">
        Conoce nuestras instalaciones:
      </h3>
      <p data-aos="fade-out">
        Explora nuestro Tour Virtual y descubre los espacios de aprendizaje
        donde transformamos sueños en realidades <strong>(Componente en Desarrollo).</strong>
      </p>
      <!-- Contenedor del visor -->
      <div ref="viewerContainer" data-aos="zoom-in" class="mx-auto viewer-container" style="width: 95%; height: 670px;">
      </div>
    </div>

    <!-- Carrusel de ubicaciones -->
    <div class="my-4">
      <h4 class="text-center">Selecciona una ubicación:</h4>
      <div id="carouselUbicaciones" class="carousel slide dark" data-bs-ride="carousel">
        <div class="carousel-inner">
          <!-- Añadido para mostrar varias imágenes a la vez -->
          <div class="carousel-item active">
            <div class="d-flex justify-content-around">
              <div v-for="ubicacion in ubicaciones.slice(0, 6)" :key="ubicacion.id" class="carousel-item-content">
                <img :src="ubicacion.thumbnail" :alt="ubicacion.nombre" class="d-block img-fluid rounded shadow" @click="cambiarPanorama(ubicacion.image)" style="cursor: pointer;" />
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="d-flex justify-content-around">
              <div v-for="ubicacion in ubicaciones.slice(0, 6)" :key="ubicacion.id" class="carousel-item-content">
                <img :src="ubicacion.thumbnail" :alt="ubicacion.nombre" class="d-block img-fluid rounded shadow" @click="cambiarPanorama(ubicacion.image)" style="cursor: pointer;" />
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselUbicaciones" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Anterior</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselUbicaciones" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Siguiente</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Viewer } from "@photo-sphere-viewer/core";
import entradaImage from "@/assets/stageCOLVM/entrada.jpg"; // Imagen inicial
import entradaImage2 from "@/assets/stageCOLVM/entrada2.jpg"; // Imagen inicial
import entradaImage3 from "@/assets/stageCOLVM/entrada3.jpg"; // Imagen inicial
import entradaImage4 from "@/assets/stageCOLVM/entrada4.jpg"; // Imagen inicial
import entradaImage5 from "@/assets/stageCOLVM/entrada5.jpg"; // Imagen inicial
import entradaImage6 from "@/assets/stageCOLVM/entrada6.jpg"; // Imagen inicial



export default {
  name: "PanoramaViewer",
  setup() {
    const viewerContainer = ref(null);
    const viewerInstance = ref(null); // Instancia del visor para actualizar dinámicamente
    const ubicaciones = ref([
      {
        id: 1,
        nombre: "Entrada Principal",
        image: entradaImage,
        thumbnail: entradaImage,
      },
      {
        id: 2,
        nombre: "Aula Magna",
        image: entradaImage2,
        thumbnail: entradaImage2,
      },
      {
        id: 3,
        nombre: "Zona Deportiva",
        image: entradaImage3,
        thumbnail: entradaImage3,
      },
      {
        id: 4,
        nombre: "Biblioteca",
        image: entradaImage4,
        thumbnail: entradaImage4,
      },
      {
        id: 5,
        nombre: "Plazoleta",
        image: entradaImage5,
        thumbnail: entradaImage5,
      },
      {
        id: 6,
        nombre: "Parque",
        image: entradaImage6,
        thumbnail: entradaImage6,
      },
      // Puedes añadir más ubicaciones aquí
    ]);

    onMounted(() => {
      // Inicializa el visor
      viewerInstance.value = new Viewer({
        container: viewerContainer.value,
        panorama: entradaImage,
        mousewheel: false,
      });
    });

    const cambiarPanorama = (imagen) => {
      // Cambia la imagen del visor dinámicamente
      viewerInstance.value.setPanorama(imagen);
    };

    return {
      viewerContainer,
      ubicaciones,
      cambiarPanorama,
    };
  },
};
</script>

<style scoped>
.viewer-container {
  border-radius: 5px;
  overflow: hidden;
}

.carousel img {
  max-height: 250px; /* Ajuste de altura para mantener la proporción */
  object-fit: cover;
  width: 100%; /* Asegura que la imagen ocupe todo el espacio disponible */
  transition: transform 0.3s ease-in-out;
}

.carousel img:hover {
  transform: scale(1.1); /* Zoom al pasar el ratón sobre las imágenes */
}

/* Responsive */
@media (max-width: 767px) {
  .viewer-container {
    height: 300px; /* Ajuste de tamaño para pantallas pequeñas */
  }

  .carousel-item-content {
    width: 30%; /* Muestra tres imágenes por slide */
  }
}

@media (min-width: 768px) {
  .viewer-container {
    height: 500px; /* Ajuste de tamaño para pantallas medianas y grandes */
  }

  .carousel-item-content {
    width: 30%; /* Muestra tres imágenes por slide */
  }
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #ff7f32; /* Fondo naranja */
  border-radius: 50%; /* Borde redondeado */
  width: 50px;
  height: 50px;
  padding: 10px;
  position: absolute; /* Posición absoluta para centrar los botones */
  top: 50%; /* Centrado vertical */
  transform: translateY(-50%); /* Ajuste perfecto para el centrado */
}

.carousel-control-prev {
  left: 10px; /* Botón anterior alineado a la izquierda */
}

.carousel-control-next {
  right: 10px; /* Botón siguiente alineado a la derecha */
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: #ff5722; /* Fondo naranja más oscuro al hacer hover */
}
</style>
