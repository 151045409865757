<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/" @click="toggleNavbar">
        <img src="@/assets/LogoColVM/Colvm_color2.svg" width="200" alt="colvm" />
      </router-link>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" :class="{ show: isNavbarOpen }" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto fw-bold mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="{ active: $route.name === 'home' }"
              to="/"
              role="button"
              aria-current="page"
              @click="toggleNavbar"
            >
              <i class="bi bi-house-door-fill"></i> INICIO
            </router-link>
          </li>

          <li v-for="(dropdown, index) in dropdowns" :key="index" class="nav-item dropdown" :class="{ show: dropdown.active }">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click.prevent="toggleDropdown(index)"
            >
              <i :class="dropdown.icon"></i> {{ dropdown.label }}
            </a>
            <ul class="dropdown-menu dropdown-menu-light px-2">
              <li v-for="(item, subIndex) in dropdown.items" :key="subIndex">
                <router-link
                  v-if="item.router"
                  class="dropdown-item"
                  :to="item.router"
                  :class="{ active: $route.name === item.name }"
                  @click="toggleNavbar"
                >
                  {{ item.label }}
                </router-link>
                <a v-else class="dropdown-item" :href="item.href" target="_blank">{{ item.label }}</a>
              </li>
            </ul>
          </li>
          
          <li class="nav-item">
            <router-link
              class="nav-link resaltado"
              :to="{ name: 'MatriculaView' }"
              :class="{ active: $route.name === 'MatriculaView' }"
              @click="toggleNavbar"
            >
              <i class="bi bi-folder-symlink"></i> MATRÍCULAS
            </router-link>
          </li>

          <li class="nav-item">
            <a class="nav-link" @click.prevent="openForm" @click="toggleNavbar" href="#">
              <i class="bi bi-envelope-arrow-down"></i> PQRS
            </a>
          </li>

          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'ComunicacionesView' }"
              :class="{ active: $route.name === 'ComunicacionesView' }"
              @click="toggleNavbar"
            >
              <i class="bi bi-messenger"></i> COMUNICACIONES
            </router-link>
          </li>
        </ul>

        <div disabled class="d-grid gap-2">
          <button disabled class="btn btn-outline-secondary disabled" @click="toggleNavbar" @click.prevent="openForm2"><i class="bi bi-caret-right-fill"></i>
            ...</button>
      </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const isNavbarOpen = ref(false);
    const dropdowns = ref([
      {
        label: "CONÓZCANOS",
        icon: "bi bi-person",
        active: false,
        items: [
          { label: "Historia", router: { name: "ResenaHView" }, name: "ResenaHView" },
          { label: "Misión y Visión", href: "#mision-vision" },
          { label: "Objetivos Institucionales", href: "#" },
          { label: "Política y Privacidad", router: { name: "PPDView" }, name: "PPDView" }
        ],
      },
      {
        label: "PLATAFORMAS DIGITALES",
        icon: "bi bi-window-stack", 
        active: false,
        items: [
          { label: "Educa.city", href: "https://plataforma.educa.city/app/web/gate/" },
          { label: "Classroom", href: "https://classroom.google.com" },
        ],
      },
      {
        label: "ÁREA ACADÉMICA",
        icon: "bi bi-journal-text",
        active: false,
        items: [
          { label: "Evaluación Docente", router: { name: "EvaluacionDView" }, name: "EvaluacionDView" },
          { label: "Horario de Clases", router: { name: "HorarioClasesView" }, name: "HorarioClasesView" },
         // { label: "Prestam de Equipo", router: { name: "PrestamoEquipoView" }, name: "PrestamoEquipoView" },
        ],
      },
    ]);

    const route = useRoute();

    const toggleDropdown = (index) => {
      dropdowns.value[index].active = !dropdowns.value[index].active;
    };

    watch(
      () => route.name,
      (newRouteName) => {
        dropdowns.value.forEach((dropdown) => {
          dropdown.active = dropdown.items.some((item) => item.name === newRouteName);
        });
      },
      { immediate: true }
    );

    const openForm2 = () => {
      window.open("https://forms.gle/4XMCPk4hyTBcgRpg6", "_blank", "width=800,height=1000");
    };

    const openForm = () => {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSd5UUfVY_nZxrTUU5Ydi2m-hKG0ouvdf-JXjk8K2YGH6Lg5fQ/viewform",
        "_blank",
        "width=800,height=1000"
      );
    };

    const toggleNavbar = () => {
      isNavbarOpen.value = !isNavbarOpen.value;
    };

    return {
      isNavbarOpen,
      dropdowns,
      toggleDropdown,
      openForm,
      openForm2,
      toggleNavbar,
    };
  },
};
</script>

<style scoped>
.nav-link.resaltado {
  animation: saltarAnimacion 1s infinite;
  font-weight: bold;
}

@keyframes saltarAnimacion {
  0%, 100% {
    transform: translateY(2); /* Posición original */
  }
  50% {
    transform: translateY(-2px); /* Salto hacia arriba */
  }
}


.navbar-nav .nav-link {
  font-size: 13px; /* Cambia este valor según el tamaño que necesites */
}

.btn{
  background-color: var(--color-co);
  color: var(--color-pr);
  border: 1px solid var(--color-pr);
}

.navbar-custom {
  background-color: #fff;
  padding: 0px 0;
  /* Ajusta el espacio interno para centrar verticalmente */
}

.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar-collapse {
  display: flex;
  justify-content: center;
}

.navbar-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 5px;
  /* Espacio entre los elementos de navegación */
}

.nav-item {
  border-radius: 5px;
  padding: 5px 0px;
}

.nav-item a {
  color: #304b76;
  text-decoration: none;
  font-size: 14px;
  border: 2px solid transparent;
  transition: border 0.3s ease-in-out;
  transition: font-size 0.3s ease-in-out;
  border-radius: 8px;
}

.nav-item a:hover {
  border: 2px solid var(--color-pr);
  font-size: 13.1px;
  color: var(--color-pr);
}

.nav-item a.active {
  color: var(--color-pr);
}

/* Estilo personalizado para el dropdown */
.navbar .dropdown-menu .dropdown-item.active,
.navbar .dropdown-menu .dropdown-item.active:hover,
.navbar .dropdown-menu .dropdown-item.active:focus {
  background-color: #ffffff;
  /* Fondo blanco para el enlace activo */
  color: var(--color-pr);
  /* Texto negro para el enlace activo, opcional */
  border: 2px solid var(--color-pr);
}

/* Estilo para el hover y focus */
.navbar .dropdown-menu .dropdown-item:hover,
.navbar .dropdown-menu .dropdown-item:focus {
  background-color: #fff3dc;
  /* Fondo claro cuando el enlace está en hover */
}
</style>
